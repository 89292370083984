.btn {
	padding:10px 20px;
	display:inline-block;
	border-radius:3px;
	background:#FF5A5F;
	font-size:16px;
	color:#fff;

	a {
		color: #fff;
		text-decoration: none;
		font-weight: 700;
	}

    .btn-icon {
        font-size: 1.2rem;
        margin: 0 10px 0 0;
    }
}

.btn-large {
	padding:20px 30px;
	font-size:22px;
	width: 100%;
	@extend .btn;
}