footer {
    border-top: 1px solid #3a3a3a;
    background-color: #212121;
    color: #f1f1f1;
    padding: 20px;
    font-size: 1.2rem;
    font-weight: 600;
    text-align: center;
    z-index: 5000;
    
    .footer-content {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        max-width: 1400px;
        margin: 0 auto;
        padding: 20px;

        .footer-social {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: space-between;
            
            .footer-social-link {
                color: #858585;
                text-decoration: none;
                font-size: 4rem;
                padding: 0 10px;
                transition: all 0.2s ease-in-out;
            
                &:hover {
                    color: #f1f1f1;
                }
            }
        }

        .footer-copy {
            font-size: 1rem;
            color: #858585;
        }
    }
}