.contact {
    max-width: 1400px;
    margin: 0 auto;
    padding: 30px 0;

    .contact-content {
        padding: 20px;
        font-size: 1.5rem;

        .contact-title {
            font-size: 3rem;
            font-family: 'Roboto Slab', serif;
            margin: 20 0 10px;
            border-top: 1px solid #3a3a3a;
            border-bottom: 1px solid #3a3a3a;
        }

        .contact-description {
            font-family: 'Montserrat', sans-serif;
        }

        .contact-form {
            width: 100%;
            text-align: left;

            .contact-form-row {
                display: flex;
                flex-direction: column;
                padding: 20px;

                label {
                    font-family: 'Roboto Slab', serif;
                    font-size: 1.5rem;
                    display: block;
                    margin: 0 0 20px;
                }

                input, textarea {
                    font-family: 'Montserrat', sans-serif;
                    font-size: 1rem;
                    padding: 10px;
                    width: 100%;
                    border: 1px solid #3a3a3a;
                    border-radius: 5px;
                }

                textarea {
                    height: 200px;
                }

                .btn-large {
                    width:101.5%;
                }
            }
        }
    }


}