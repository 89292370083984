.App {
  text-align: center;
}

section {
  font-size: calc(10px + 2vmin);
  color: white;

  &.header {
    background-color: rgba(#212121,.9);
    position: fixed;
    width: 100%;
    z-index: 5000;
    
    .content {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      max-width: 1400px;
      margin: 0 auto;
      padding: 20px;

      .logo {
        
        img {
          height: 8vmin;
          pointer-events: none;
        }
      }

      .menu {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        width: 300px;

        .menu-item {
          color: #858585;
          text-decoration: none;
          font-size: 1.2rem;
          font-weight: 600;
          padding: 0 10px;
          transition: all 0.2s ease-in-out;

          &:hover {
            color: #f1f1f1;
          }
        }

      }
    }
  }
}

@import 'scss/hero.scss';
@import 'scss/button.scss';
@import 'scss/about.scss';
@import 'scss/listen.scss';
@import 'scss/contact.scss';
@import 'scss/footer.scss';
