.hero {
    background: url('../../public/hero-bg.jpg') no-repeat center center;
    background-size: cover;
    padding: 180px 20px 80px;
    min-height: 300px;
    position: relative;

    .img-overlay {
        background: rgba(#000, .8);
        height: 100%;
        width: 100%;
        display: block;
        position: absolute;
        top: 0;
        left: 0;
    }

    .hero-content {
        position: relative;
        z-index: 4999;
        text-align: left;
        max-width: 1400px;
        margin: 0 auto;
        padding: 20px;

        .hero-title {
            color: #fff;
            font-family: 'Roboto Slab', serif;
            font-size: 3rem;
            font-weight: 600;
            margin-bottom: 20px;
        }

        .hero-description {
            color: #fff;
            max-width: 600px;
            font-family: 'Montserrat', sans-serif;
            font-size: 1.5rem;
            font-weight: 300;
            margin-bottom: 20px;
        }
    }
}