.listen-now {
    max-width: 1400px;
    margin: 0 auto;
    padding: 30px 0;

    .listen-now-content {
        padding: 20px;
        font-size: 1.5rem;

        .listen-now-title {
            font-size: 3rem;
            font-family: 'Roboto Slab', serif;
            margin: 20 0 10px;
            border-top: 1px solid #3a3a3a;
            border-bottom: 1px solid #3a3a3a;
        }
        .listen-now-links {
            display: flex;
            flex-direction: row;
            align-items: start;
            justify-content:center;
            padding: 20px;

            .listen-now-link {
                font-size: 4rem;
                padding: 0 30px;
                color: #858585;
                text-decoration: none;
                transition: all 0.2s ease-in-out;

                span {
                    font-size: 1.2rem;
                }

                &:hover {
                    color: #f1f1f1;
                }
            }
        }
    }
}
