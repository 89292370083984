.about-wtad {
    max-width: 1400px;
    margin: 0 auto;
    padding: 30px 0;

    .about-wtad-content{
        padding: 20px;
        font-size: 1.5rem;

        .about-wtad-title {
            font-size: 3rem;
            font-family: 'Roboto Slab', serif;
            margin: 20 0 10px;
            border-top: 1px solid #3a3a3a;
            border-bottom: 1px solid #3a3a3a;
        }

        .about-wtad-description {
            font-family: 'Montserrat', sans-serif;
        }
    }
    
    .about-wtad-hosts{
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        flex-wrap: nowrap;
        align-items: start;
        padding: 20px;

        .about-wtad-host {
            padding: 0 10px;
            max-width: 50%;

            .about-wtad-host-img {
                margin: 0 0 20px;
                img {
                    border-radius: 50%;
                    width: 300px;
                    height: 300px;
                    border: 10px solid #FF5A5F
                }
            }

            .about-wtad-host-name {
                font-family: 'Roboto Slab', serif;
                font-size: 2rem;
            }  
            
            .about-wtad-host-description {
                padding: 20px 20px 0;
            }

            @media screen and (max-width: 768px) {
                max-width: 100%;
                flex-wrap: wrap;
                margin: 0 0 50px;
            }
        }
    }
}
